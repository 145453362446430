<template>
	<div class='single-pages'>
		<div class='single-header'>请选择还款期数</div>
		<div class='single-main'>
			<div :class="item.currentPeriod=='YES' ? 'single-mian-line-ben':'single-mian-line'" v-for='item in planList' :key='item.id' @click='changeItem(item,item.checked)'>
				<div class='main-line-left'>
					<!-- 如果是本期的话当前line新增一个样式 -->
					<span class='line-left-npce'>第{{item.planIndex}}期</span>
					<span class='line-left-text'  v-show='item.currentPeriod=="NO" && item.status=="OVERDUED"'>(已逾期)</span>
					<span class='line-left-text-issue' v-show='item.currentPeriod=="YES"'>(本期)</span>
					<span class='line-left-text-before' v-show='item.currentPeriod=="NO" && item.status!="OVERDUED"'>(未到期)</span>
				</div>
				<div class='main-line-right'>
                    <!-- item.remainAmt.toFixed(2) $filters.formatMoney(item.planAmt, 2)-->
					<span class='line-right-num'>{{item.planAmt.toFixed(2)}}</span>
					<span :class="item.checked==false?'line-right-icon':'line-right-icon-r'"></span>
				</div>
			</div>
		</div>
		<div class='single-footer'>
			<div class='single-footer-left'>
				<span class='left-text'>还款金额</span>
				<span class='left-money'>¥ {{payAmt.toFixed(2)}}</span>
			</div>
			<div class='single-footer-right' @click='submit'>
				确认
			</div>
		</div>
	</div>
</template>

<script>
import { toRefs,reactive,onMounted,computed, onBeforeUpdate,ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { routerTag } from '../../utils/index'
import API from '../../server/api'
import APP from '@/utils/App'
import { create, all } from 'mathjs';
const config = ({number: 'BigNumber',precision: 20});
const math = create(all, config);
export default {
        setup(){
            const state = reactive({
                list: ['1', '2', '3', '4'],
                checkboxRefs: [],
                checkeds: [],
                allPrice: 0,
                periodList: [],
                loanresult: {},
                backResult: null,
                checked: true,
                loanNo:'',
                planList:[],
				payArray: [],
				payAmt:0,
				capitalCode:null,
				LoanInfo:null
            })
             const init = async () => {
                const res = await API.getloanInfo({loanNo:state.loanNo})
                console.log(res, '哈哈哈')
                // state.loanresult = result
                const {planList, }=res.result
                    state.loanresult =res.result
					planList.forEach((item) => {
					          item.checked = false;
					});
					// console.log(planRemindList,loanVO);
					state.planList=planList
					// state.capitalCode=loanVO.capitalCode
					state.LoanInfo=res.result
            }
            const changeItem=(item,flag)=>{
                console.log(item,flag,999)
				const {planIndex}=item
				if(item.status=='OVERDUED' || item.currentPeriod=='YES'){
				  if (!flag) {
                      console.log(98)
				        state.planList.forEach((r) => {
				          if ( state.capitalCode != "OWNBUSINESS") {
								 console.log(97)
				            if (r.status ===  r.status !== 'SETTLED'  && r.planIndex <= planIndex ) {
                                 console.log(96)
				              r.checked = true;
				              const index = state.payArray.indexOf(r.planIndex);
				              if (index === -1) {
                                  
				                state.payArray.push(r.planIndex);
				                state.payAmt = math.number(math.add(math.bignumber(state.payAmt), math.bignumber(r.planAmt * 1)));
                                 console.log(95,state.payAmt)
				              }
				            }
				  
				          } else {
							 
				            if (r.status !== 'SETTLED' && r.planIndex <= planIndex) {
				              r.checked = true;
				              const index = state.payArray.indexOf(r.planIndex);
				              if (index === -1) {
				                state.payArray.push(r.planIndex);
				                state.payAmt = math.number(math.add(math.bignumber(state.payAmt), math.bignumber(r.planAmt * 1)));
				              }
				            }
				          }
				  
				  
				        })
				  
				      } else {
					
				        state.planList.forEach((r) => {

				          if (r.planIndex >= planIndex && r.checked) {
				            r.checked = false;
				            const index = state.payArray.indexOf(r.planIndex);
				            if (index !== -1) {
				              state.payArray.splice(index, 1);
				            }
				            state.payAmt = math.number(math.subtract(math.bignumber(state.payAmt), math.bignumber(r.planAmt * 1)));
				          }
				        })
				      }
				}
			}
			const submit=async()=>{
                // const newData = state.periodList.filter((item,index,array)=>{
                //     return array.indexOf(item)===index
                // })
				if (state.payAmt==0) {
					  Toast.fail('请选择还款期数！')
					  return false
				}else{
                    state.backResult = await API.backFee({
                        isSettle: false,
                        loanNo: state.loanNo,
                        periodList: state.payArray
                    })
                    console.log('confirm')
                    localStorage.setItem('backResult', JSON.stringify(state.backResult))
                    APP.JUMP_TO(`${routerTag}payrepayment`)
                }
			}
            onMounted(() => {
                state.loanNo = localStorage.getItem('loanNos')
                init()
            })
            return {
                ...toRefs(state),
                submit,
                changeItem,
                
            }
        }
	}
</script>
<style  src="./newpage.css" ></style>
